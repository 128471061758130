<template>
	<div class="right-box">
		<dataplat-search @submit="handleSubmit" ref="dataplatSearch"></dataplat-search>
		<DownloadBtn @exportData="exportData" :id="1" :type=1
			:style="{position: 'relative', left: 0, 'margin-bottom': '10px','background-color' : 'rgba(241, 110, 113, 1)'}">
		</DownloadBtn>
		<span class="unit" :style="{position: 'absolute',top: '215px',right: '25px'}">币种：{{bz}}</span>
		<dataplat-content :columns="tableColumns" :list="tableList" :total="total" @changePage="handleChangePage"
			@changePageSize="handleChangePageSize"></dataplat-content>
	</div>
</template>

<script>
	import SearchPanel from "./components/SearchPanel";
	import ContentPanel from "@/components/ContentPanel";
	import DownloadBtn from "@/components/DownloadBtn";
	import {
		post_enterprise_xsqkList
	} from "@/api/houseEnterprise";
	import {
		downloadData,
		formatJson,
		awaitWrap
	} from "@/utils/index";
	import dataReports from "@/mixins/dataReports";
	export default {
		name: "saleHouse",
		mixins: [dataReports],
		components: {
			"dataplat-search": SearchPanel,
			"dataplat-content": ContentPanel,
			DownloadBtn,
		},
		computed: {
			code() {
				return this.$store.state.reHouseEnterprise.selectedCompantGpmc
			},
		},
		data() {
			return {
				tableColumns: [],
				tableList: [],
				total: 0,
				searchForm: {},
				bz: "",
			};
		},
		watch: {
			code(newVal, oldVal) {
				// if (newVal !== oldVal && this.$route.query.code) {
				// 	this.$store.state.componeyCode = this.$route.query.code;
				// 	// this.$refs.dataplatSearch.handleSearch();
				// }
				this.$refs.dataplatSearch.handleSearch();
			},
		},
		methods: {
			handleChangePage(page) {
				this.searchForm.current = page;
				this.handleSubmit(this.searchForm);
			},
			async exportData() {
				const columns = [{
						title: "日期",
						key: "rq",
						ellipsis: true,

						tooltip: true,

						align: "center",
					},
					{
						title: "销售面积",
						ellipsis: true,

						tooltip: true,

						renderHeader: (h, index) => {
							return h("div", [h("div", "销售面积"), h("div", "(万m²)")]);
						},
						align: "center",
						key: "xsmj",
					},
					{
						title: "同比增长",
						align: "center",
						ellipsis: true,

						tooltip: true,

						key: "xsmjtbzz",
					},
					{
						title: "销售金额",
						ellipsis: true,

						tooltip: true,

						renderHeader: (h, index) => {
							return h("div", [h("div", "销售金额"), h("div", "(亿元)")]);
						},
						align: "center",
						key: "xsje",
					},
					{
						title: "同比增长",
						align: "center",
						ellipsis: true,

						tooltip: true,

						key: "xsjetbzz",
					},
					{
						title: "销售均价",
						ellipsis: true,

						tooltip: true,

						renderHeader: (h, index) => {
							return h("div", [h("div", "销售均价"), h("div", "(元/m²)")]);
						},
						align: "center",
						key: "xsjj",
					},
					{
						title: "同比增长",
						ellipsis: true,

						tooltip: true,

						align: "center",
						key: "xsjjtbzz",
					},
					{
						title: "销售目标",
						ellipsis: true,
						tooltip: true,

						renderHeader: (h, index) => {
							return h("div", [h("div", "销售目标"), h("div", "(亿元)")]);
						},
						align: "center",
						key: "xsmb",
					},
					{
						title: "目标完成度",
						ellipsis: true,

						tooltip: true,

						align: "center",
						key: "mbwcd",
					},
				];
				const searchForm = this.searchForm;
				searchForm.size = 10000000;
				searchForm.current = 1;
				let requestParam = Object.assign(searchForm, {
					code: this.code, // 股票代码
				});

				// let [err,data] = await post_enterprise_xsqkList(requestParam).then(data => [null, data] ).catch(err => [err, null])
				let [err, data] = await awaitWrap(post_enterprise_xsqkList(requestParam))
				let filterVal = [];
				let tHeader = [];
				columns.forEach((item) => {
					tHeader.push(item.title);
					filterVal.push(item.key);
				});
				const resData = formatJson(filterVal, data.records);
				downloadData(tHeader, resData, "销售");


			},
			handleChangePageSize(page) {
				this.searchForm.size = page;
				this.handleSubmit(this.searchForm);
			},
			handleSubmit(params) {
				this.searchForm = params;
				let requestParam = Object.assign(params, {
					code: this.code, // 股票代码
				});
				const columns = [{
						title: "日期",
						ellipsis: true,

						tooltip: true,

						align: "center",
						key: "rq",
					},
					{
						title: "销售面积",
						renderHeader: (h, index) => {
							return h("div", [h("div", "销售面积"), h("div", "(万m²)")]);
						},
						align: "center",
						ellipsis: true,

						tooltip: true,

						key: "xsmj",
					},
					{
						title: "同比增长",
						ellipsis: true,

						tooltip: true,

						key: "xsmjtbzz",
						align: "center",
					},
					{
						title: "销售金额",
						ellipsis: true,

						tooltip: true,

						renderHeader: (h, index) => {
							return h("div", [h("div", "销售金额"), h("div", "(亿元)")]);
						},
						align: "center",
						key: "xsje",
					},
					{
						title: "同比增长",
						align: "center",
						key: "xsjetbzz",
						ellipsis: true,

						tooltip: true,
					},
					{
						title: "销售均价",
						ellipsis: true,

						tooltip: true,

						renderHeader: (h, index) => {
							return h("div", [h("div", "销售均价"), h("div", "(元/m²)")]);
						},
						align: "center",
						key: "xsjj",
					},
					{
						title: "同比增长",
						align: "center",
						ellipsis: true,

						tooltip: true,

						key: "xsjjtbzz",
					},
					{
						title: "销售目标",
						ellipsis: true,

						tooltip: true,

						renderHeader: (h, index) => {
							return h("div", [h("div", "销售目标"), h("div", "(亿元)")]);
						},
						align: "center",
						key: "xsmb",
					},
					{
						title: "目标完成度",
						ellipsis: true,

						tooltip: true,

						align: "center",
						key: "mbwcd",
					},
				];
				post_enterprise_xsqkList(requestParam)
					.then((data) => {

						this.tableColumns = columns;
						this.total = data.total;
						this.tableList = data.records || [];
						this.bz = this.tableList[0] ? this.tableList[0].bz : "";
					})
					.catch((e) => {

					});
			},
		},
	};
</script>
