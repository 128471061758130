<template>
  <div class="home">
    <Card>
      <Form :model="searchForm" :label-width="120" :rules="ruleValidate" inline>
        <FormItem label="周期">
          <!-- <Select v-model="searchForm.zq" style="width:80px" @on-change="(value) => this.handleChangeCycle('周期', 'string', 'zq', value)">
                        <Option v-for="item in zqList" :value="item.name" :key="item.id">{{ item.name }}</Option>
                    </Select> -->
          &nbsp;
          <DateRange
            v-model="searchForm.rq"
            :dateType="dateType"
            ref="DateRange"
            @change="
              (value) => this.handleChangeOption('日期', 'date', 'rq', value)
            "
          ></DateRange>
        </FormItem>
        <FormItem label="统计方式" prop="tjfs">
          <RadioGroup
            v-model="searchForm.tjfs"
            @on-change="
              (data) => this.handleChangeTjfs('统计方式', 'radio', 'tjfs', data)
            "
          >
            <Radio
              :label="item.id"
              v-for="item in radioList.tjfs"
              :key="item.id"
              >{{ item.name }}</Radio
            >
          </RadioGroup>
        </FormItem>
        <FormItem label="统计口径" prop="tjkj">
          <RadioGroup
            v-model="searchForm.tjkj"
            @on-change="
              (data) =>
                this.handleChangeValue('统计口径', 'radio', 'tjkj', data)
            "
          >
            <Radio
              :label="item.tjkj"
              v-for="item in radioList.tjkj"
              :key="item.tjkj"
              >{{ item.tjkj }}</Radio
            >
          </RadioGroup>
        </FormItem>
        <FormItem>
          <Button type="primary searchBtnLog" data-id="1" @click="handleSearch">查询</Button>
        </FormItem>
      </Form>
    </Card>
    <div class="tagbox">
      <template v-if="Object.keys(selectedList).length !== 0">
        已选条件：
        <template v-for="item in selectedList">
          <span v-if="item !== null" :key="item.formItem">
            <Tag
              v-for="(name, index) in item.data"
              :key="name"
              type="border"
              closable
              @on-close="() => handleClose(index, item)"
            >
              <span v-if="item">{{ item.title }}:</span>
              <span>{{ name }} </span>
            </Tag>
          </span>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { post_enterprise_tjkj } from "@/api/houseEnterprise";
import tagBoxMixin from "@/views/share/tagBoxMixin";
import dateHandleMixin from "@/views/share/dateHandleMixin";
import { checkDate } from "@/utils/index";
export default {
  name: "SearchPanel",
  components: {},
  mixins: [tagBoxMixin, dateHandleMixin],
  data() {
    return {
      searchForm: {
        rq: ["2017-01", new Date().toLocaleDateString()], // 日期 yyyy年mm月
        tjfs: "累计", // 统计方式
        tjkj: "", // 统计口径
        zq: "月度", // 周期
        size: 10,
        current: 1,
      },
      zqList: [
        {
          id: "月度",
          name: "月度",
        },
        {
          id: "季度",
          name: "季度",
        },
        {
          id: "半年度",
          name: "半年度",
        },
        {
          id: "年度",
          name: "年度",
        },
      ],
      radioList: {
        tjfs: [
          {
            id: "单月",
            name: "单月",
          },
          {
            id: "累计",
            name: "累计",
          },
        ],
        tjkj: [],
      },
      dateType: "month",
      selectedList: {}, //已选条件
      ruleValidate: {
        tjfs: [{ required: true, message: "", trigger: "change" }],
        tjkj: [{ required: true, message: "", trigger: "change" }],
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getCheckboxList("tjkj");
    },
    getCheckboxList(type) {
      post_enterprise_tjkj()
        .then((data) => {
          this.radioList[type] = data;
          this.searchForm.tjkj = this.radioList[type][0].tjkj;
          this.handleChangeTjfs("统计方式", "radio", "tjfs", "累计");
          this.handleChangeValue(
            "统计口径",
            "radio",
            "tjkj",
            this.radioList[type][0].tjkj
          );
          this.handleSearch();
        })
        .catch((e) => {

        });
    },
    handleChangeCycle(name, type, formItem, value) {
      this.searchForm[formItem] = value;
      if (value === "月度") {
        this.dateType = "month";
      } else if (value === "季度") {
        this.dateType = "jd";
      } else {
        this.dateType = "year";
      }
    },
    handleChangeTjfs(name, type, formItem, value) {
      const checkedValue = value;
      const radioList = this.radioList[formItem];
      let list = [];
      for (let i = 0, len = radioList.length; i < len; i++) {
        if (radioList[i].id === checkedValue) {
          list.push(radioList[i].name);
          break;
        }
      }
      this.handleChangeOption(name, type, formItem, list);
    },
    handleChangeValue(name, type, formItem, value) {
      const checkedValue = value;
      const radioList = this.radioList[formItem];
      let list = [];
      for (let i = 0, len = radioList.length; i < len; i++) {
        if (radioList[i].tjkj === checkedValue) {
          list.push(radioList[i].tjkj);
          break;
        }
      }
      this.handleChangeOption(name, type, formItem, list);
    },
    checkData(data) {
      if (!checkDate(data.rq[0], data.rq[1])) {
        this.$msg.error({ type: "remind", code: 4015 });

        return false;
      }
      return true;
    },
    handleSearch() {
      const { rq } = this.searchForm;
      // if (!checkDate(rq[0], rq[1])) {
      //   return;
      // }
      let searchForm = this.handleDateRule(rq, "rq", "日期", this.searchForm);
      if (searchForm === null) {
        return;
      }
      if (!this.checkData(this.searchForm)) return;
      this.$emit("submit", searchForm);
    },
  },
};
</script>
